<template>
  <b-sidebar id="sidebar" :visible="isSidebarActive" bg-variant="white" sidebar-class="sidebar-lg" shadow backdrop
    no-header no-close-on-backdrop right @shown="loadData" @hidden="resetForm"
    @change="(val) => $emit('update:is-sidebar-active', val)">
    <template #default="{ hide }">
      <!-- Header -->
      <div class="
          d-flex
          justify-content-between
          align-items-center
          content-sidebar-header
          px-2
          py-1
        ">
        <h5 class="mb-0 text-capitalize">
          {{
              isAdd ? $t("incident.add_incident") : $t("incident.edit_incident")
          }}
        </h5>

        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>

      <!-- BODY -->
      <validation-observer #default="{ handleSubmit }" ref="refFormObserver">
        <!-- Form -->
        <b-form class="p-2" @submit.prevent="handleSubmit(onSubmit)" @reset.prevent="resetForm">
          <!-- tenant -->
          <!-- <validation-provider #default="validationContext" name="tenant" rules="required">
            <b-form-group label="Tenant" label-for="id_tenant" :state="getValidationState(validationContext)">
              <v-select v-model="itemData.id_tenant" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="tenantOptions" :clearable="false" :reduce="(val) => val.value" input-id="id_tenant" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider> -->

          <!-- Name -->
          <validation-provider #default="validationContext" name="name" rules="required">
            <b-form-group class="text-capitalize" :label="$t('incident.name')" label-for="name">
              <b-form-input id="name" v-model="itemData.name" :state="getValidationState(validationContext)" trim />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Color -->
          <validation-provider #default="validationContext" name="color" rules="required">
            <b-form-group class="text-capitalize" :label="$t('incident.colour')" label-for="color">
              <b-form-input id="description" v-model="itemData.color" :state="getValidationState(validationContext)"
                trim type="color" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- alarm -->
          <validation-provider name="alarm" rules="required" vid="alarm">
            <b-form-checkbox id="alarm" v-model="itemData.alarm" name="alarm" value="1" unchecked-value="0"
              class="my-1 text-capitalize" switch>
              {{ $t("incident.alarm") }}
            </b-form-checkbox>
          </validation-provider>

          <!-- tipo alarma -->
          <validation-provider #default="validationContext" name="id_type_alarm" rules="required_if:alarm,1"
            v-if="itemData.alarm == 1">
            <b-form-group class="text-capitalize" :label="$t('incident.alamr_type')" label-for="id_type_alarm"
              :state="getValidationState(validationContext)">
              <v-select v-model="itemData.id_type_alarm" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="typeAlarmOptions" :clearable="false" :reduce="(val) => val.value" input-id="id_type_alarm" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- video -->
          <validation-provider name="video" rules="required">
            <b-form-checkbox id="video" v-model="itemData.video" name="video" value="1" unchecked-value="0"
              class="my-1 text-capitalize" switch>
              Video
            </b-form-checkbox>
          </validation-provider>

          <!-- Prioridad -->
          <validation-provider #default="validationContext" name="priority" rules="required">
            <b-form-group class="text-capitalize" :label="$t('incident.priority')" label-for="priority">
              <b-form-input id="priority" v-model="itemData.priority" :state="getValidationState(validationContext)"
                trim type="number" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- resolutions -->
          <validation-provider #default="validationContext" name="resolution" rules="required">
            <b-form-group class="text-capitalize" :label="$t('incident.resolution')" label-for="resolution"
              :state="getValidationState(validationContext)">
              <v-select v-model="itemData.resolution" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                :options="resolutions" :clearable="false" :reduce="(val) => val.value" input-id="resolution" />
              <b-form-invalid-feedback :state="getValidationState(validationContext)">
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- FPS -->
          <validation-provider #default="validationContext" name="frames_per_second" rules="required">
            <b-form-group class="text-capitalize" :label="$t('incident.frames_for_second')"
              label-for="frames_per_second">
              <b-form-input id="frames_per_second" v-model="itemData.frames_per_second"
                :state="getValidationState(validationContext)" trim type="number" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Description -->
          <validation-provider #default="validationContext" name="timeout_live_video" rules="required">
            <b-form-group l class="text-capitalize" :label="$t('incident.live_video_time')"
              label-for="timeout_live_video">
              <b-form-input id="timeout_live_video" v-model="itemData.timeout_live_video"
                :state="getValidationState(validationContext)" trim type="number" />

              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </b-form-group>
          </validation-provider>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" class="mr-2 text-capitalize"
              type="submit">
              {{ isAdd ? $t("actions.add") : $t("actions.edit") }}
            </b-button>
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" type="button" variant="outline-secondary" @click="hide"
              class="text-capitalize">
              {{ $t('actions.cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BSidebar,
  BForm,
  BFormGroup,
  BFormInput,
  BFormInvalidFeedback,
  BButton,
  BFormCheckbox,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import { required, alphaNum, email, positive, required_if } from "@validations";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import formValidation from "@core/comp-functions/forms/form-validation";
import Ripple from "vue-ripple-directive";
import store from "@/store";

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,
    BFormCheckbox,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  model: {
    prop: "isSidebarActive",
    event: "update:is-sidebar-active",
  },
  props: {
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    itemEdit: {
      type: Object,
      required: true,
    },
    tenantOptions: {
      type: Array,
      required: true,
    },
    typeAlarmOptions: {
      type: Array,
      required: true,
    },
    isAdd: {
      type: Boolean,
      required: true,
    },
    idIncidentList: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      required,
      alphaNum,
      email,
      positive,
    };
  },
  setup(props, { emit }) {
    const blankData = {
      name: "",
      id_tenant: null,
      id_type_alarm: null,
      alarm: "0",
      video: "0",
      priority: 0,
      resolution: "VR_640x480",
      frames_per_second: 1,
      timeout_live_video: 10,
    };

    const resolutions = [
      {
        value: "VR_1920x1080",
        label: "VR_1920x1080",
      },
      {
        value: "VR_1280x720",
        label: "VR_1280x720",
      },
      {
        value: "VR_720x480",
        label: "VR_720x480",
      },
      {
        value: "VR_320x240",
        label: "VR_320x240",
      },
      {
        value: "VR_3840x2160",
        label: "VR_3840x2160",
      },
      {
        value: "VR_720x576",
        label: "VR_720x576",
      },
      {
        value: "VR_640x480",
        label: "VR_640x480",
      },
      {
        value: "VR_352x288",
        label: "VR_352x288",
      },
      {
        value: "VR_176x144",
        label: "VR_176x144",
      },
      {
        value: "VR_640x360",
        label: "VR_640x360",
      },
      {
        value: "VR_720x405",
        label: "VR_720x405",
      },
      {
        value: "VR_864x486",
        label: "VR_864x486",
      },
      {
        value: "VR_960x540",
        label: "VR_960x540",
      },
      {
        value: "VR_4096x2160",
        label: "VR_4096x2160",
      },
      {
        value: "VR_1920x1200",
        label: "VR_1920x1200",
      },
    ];

    const toast = useToast();

    const itemData = ref(JSON.parse(JSON.stringify(blankData)));
    const resetData = () => {
      itemData.value = JSON.parse(JSON.stringify(blankData));
    };

    const onSubmit = () => {
      let dispatch;

      if (props.isAdd) {
        
        dispatch = store.dispatch("incident/add", {...itemData.value, id_incident_list : props.idIncidentList});
      } else {
        const req = {
          id: itemData.value.id,
          params: {
            name: itemData.value.name,
            id_type_alarm: itemData.value.id_type_alarm,
            alarm: itemData.value.alarm,
            video: itemData.value.video,
            color: itemData.value.color,
            priority: itemData.value.priority,
            resolution: itemData.value.resolution,
            frames_per_second: itemData.value.frames_per_second,
            timeout_live_video: itemData.value.timeout_live_video,
          },
        };

        dispatch = store.dispatch("incident/edit", req);
      }

      dispatch
        .then((response) => {
          if (response.success) {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "CheckIcon",
                variant: "success",
              },
            });
            emit("refetch-data");
            emit("update:is-sidebar-active", false);
          } else {
            toast({
              component: ToastificationContent,
              props: {
                title: response.message,
                icon: "AlertTriangleIcon",
                variant: "danger",
              },
            });
          }
        })
        .catch((response) => {
          toast({
            component: ToastificationContent,
            props: {
              title: response.response.data.message,
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    };

    const { refFormObserver, getValidationState, resetForm } =
      formValidation(resetData);

    const loadData = () => {
      if (!props.isAdd) {
        itemData.value = props.itemEdit;
      }
    };

    return {
      itemData,
      onSubmit,

      refFormObserver,
      getValidationState,
      loadData,
      resetForm,
      resolutions,
    };
  },
};
</script>