<template>
    <b-card no-body class="m-2">
        <b-card-header class="pb-50">
            <h3 class="text-capitalize">
                {{incidentListName}}
            </h3>
        </b-card-header>
        <b-card-header class="pb-50">
            <h5 class="text-capitalize">
                {{ $t('filter') }}
            </h5>
        </b-card-header>
        <b-card-body>
            <b-row>
                <b-col cols="4" md="4" class="mb-md-0 mb-2">
                    <label>Incident List</label>
                    <v-select :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :value="listFilter"
                        :options="listOptions" class="w-100" :reduce="val => val.value"
                        @input="(val) => $emit('update:listFilter', val)" />
                </b-col>
            </b-row>
        </b-card-body>
    </b-card>
</template>

<script>
import {
    BCard,
    BCardHeader,
    BCardBody,
    BRow,
    BCol,
    BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
    components: {
        BRow,
        BCol,
        BCard,
        BCardHeader,
        BCardBody,
        BButton,
        vSelect,
    },
    props: {
        tenantOptions: {
            type: Array,
            default: null,
        },
        tenantFilter: {
            type: Array,
            default: null,
        },
        incidentListName: {
            type: String,
            default: null,
        },
        listOptions: {
            type: Array,
            default: null,
        },
        listFilter: {
            type: Number,
            default: null,
        },
    },
}
</script>