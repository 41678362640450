<template>
  <div>
    <sidenav
      :is-sidebar-active.sync="isSidebarActive"
      :item-edit.sync="itemEdit"
      @refetch-data="refetchData"
      :tenant-options="tenantOptions"
      :is-add.sync="isAdd"
      :type-alarm-options="typeAlarmOptions"
      :id-incident-list="listFilter"
    />

    <!-- Filters -->
    <filters
      :tenant-filter.sync="tenantFilter"
      :tenant-options="tenantOptions"
      :incident-list-name="incident_list_name"
      :list-options="listOptions"
      :list-filter.sync="listFilter"
    />

    <!-- Table Container Card -->
    <b-card class="m-2" no-body>
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label class="text-capitalize">{{ $t("table.show") }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label class="text-capitalize">{{ $t("table.entries") }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="searchQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('table.search')"
              />
              <b-button variant="secondary" @click="openAdd()">
                <span class="text-nowrap text-capitalize">{{
                  $t("incident.add_incident")
                }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refListTable"
        class="position-relative"
        :items="fetchList"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('no_record_found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #head()="data">
          <span> {{ $tc(data.label, 1) }}</span>
        </template>

        <template #head(name)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(tenant)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(type_alarm)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(color)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(alarm)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(video)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(priority)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(resolution)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(frames_per_second)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>
        <template #head(timeout_live_video)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(created_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(updated_at)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(master_tenant)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <template #head(actions)="data">
          <span> {{ $tc(data.label, 2) }}</span>
        </template>

        <!-- A custom formatted header cell for field 'name' -->
        <template #cell(color)="data">
          <div
            :style="
              'height: 25px; width: 25px; border-radius: 16px;background:' +
              data.item.color
            "
          ></div>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>

            <b-dropdown-item @click="openEdit(data.item)">
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.edit")
              }}</span>
            </b-dropdown-item>

            <b-dropdown-item @click="confirmDelete(data.item.id)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50 text-capitalize">{{
                $t("actions.delete")
              }}</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span class="text-muted text-capitalize">{{
              $t("table.showing", {
                from: dataMeta.from,
                to: dataMeta.to,
                of: dataMeta.of,
              })
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalList"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BTable,
  BButton,
  // BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
} from "bootstrap-vue";
import vSelect from "vue-select";
import { ref } from "@vue/composition-api";
import useList from "./useList";
import Sidenav from "./Sidenav.vue";
import Filters from "./Filters.vue";
import { useToast } from "vue-toastification/composition";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import store from "@/store";
import { useUtils as useI18nUtils } from "@core/libs/i18n";

export default {
  components: {
    Sidenav,
    Filters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BDropdown,
    BDropdownItem,
    BPagination,

    vSelect,
  },
  setup(_, { emit }) {
    const isSidebarActive = ref(false);
    const itemEdit = ref({});
    const isAdd = ref(false);

    const openEdit = (item) => {
      isSidebarActive.value = true;
      itemEdit.value = item;
      isAdd.value = false;
    };
    const { t } = useI18nUtils();

    const openAdd = () => {
      isSidebarActive.value = true;
      isAdd.value = true;
    };

    const toast = useToast();

    const {
      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,

      typeAlarmOptions,
      tenantOptions,
      tenantFilter,
      id_incident_list,
      incident_list_name,
      listOptions,
      listFilter,
    } = useList();

    const confirmDelete = function (id) {
      const self = this;
      const storeInner = store;
      this.$swal({
        title: "¿Estas seguro de eliminarlo?",
        text: "Una vez eliminado se borraran todos los registros asociados",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Eliminar",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          storeInner
            .dispatch("incident/delete", id)
            .then((response) => {
              if (response.success) {
                toast({
                  component: ToastificationContent,
                  props: {
                    title: response.message,
                    icon: "CheckIcon",
                    variant: "success",
                  },
                });
                self.refetchData();
              } else {
                toast(
                  {
                    component: ToastificationContent,
                    props: {
                      title: response.message,
                      icon: "AlertTriangleIcon",
                      variant: "danger",
                    },
                  },
                  {
                    timeout: 10000,
                  }
                );
              }
            })
            .catch((response) => {
              toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: response.response.data.message,
                    icon: "AlertTriangleIcon",
                    variant: "danger",
                  },
                },
                {
                  timeout: 10000,
                }
              );
            });
        }
      });
    };

    return {
      // Sidebar
      isSidebarActive,
      isAdd,
      itemEdit,
      openEdit,
      openAdd,

      confirmDelete,

      fetchList,
      tableColumns,
      perPage,
      currentPage,
      totalList,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refListTable,
      refetchData,
      formatDateAssigned,
      listFilter,
      listOptions,
      typeAlarmOptions,
      tenantOptions,
      tenantFilter,
      id_incident_list,
      incident_list_name,
    };
  },
};
</script>