import { ref, computed, watch } from '@vue/composition-api'
// import { title } from '@core/utils/filter'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import moment from 'moment-timezone'
import store from '@/store'
import router from '@/router'
import { debounce } from 'lodash'

export default function useList() {
    const refListTable = ref(null)

    const toast = useToast()

    store.dispatch('tenant/getAll')
    store.dispatch('type_alarm/getAll')
    store.dispatch('incident_list/getAll')

    const typeAlarmOptions = computed(() => store.getters['type_alarm/getSelectedItems'])
    const tenantOptions = computed(() => store.getters['tenant/getSelectedItems'])
    const tenantFilter = ref(null)
    const listFilter = ref(null)
    const listOptions = computed(() => store.getters['incident_list/getSelectedItems'])
    const id_incident_list = ref(null)
    const incident_list_name = ref(null)

    if (router.currentRoute.params.id_incident_list){
        listFilter.value = parseInt(router.currentRoute.params.id_incident_list)
    }else{
        console.log(listOptions.value[0]);
        listFilter.value = listOptions.value[0].value
    }

    if (router.currentRoute.params.id_tenant)
        tenantFilter.value = [parseInt(router.currentRoute.params.id_tenant)]

    if (router.currentRoute.params.incident_list_name)
    incident_list_name.value = new String (router.currentRoute.params.incident_list_name)



    const tableColumns = [
        { label: 'actions.name', key: 'actions' },
        { label: 'incident.name', key: 'name', sortable: true },
        { label: 'incident.alarm_type', key: 'type_alarm', sortable: true },
        {
            label: 'incident.colour',
            key: 'color',
            sortable: false,
        },
        { label: 'incident.alarm', key: 'alarm', sortable: true },
        { label: 'Video', key: 'video', sortable: true },
        { label: 'incident.priority', key: 'priority', sortable: true },
        { label: 'incident.resolution', key: 'resolution', sortable: true },
        { label: 'incident.frames_for_second', key: 'frames_per_second', sortable: true },
        { label: 'incident.live_video_time', key: 'timeout_live_video', sortable: true },
        {
            label: 'date.created_at', key: 'created_at', sortable: true, formatter: 'formatDateAssigned',
        },
        {
            label: 'date.updated_at', key: 'updated_at', sortable: true, formatter: 'formatDateAssigned',
        },
    ]

    const perPage = ref(10)
    const totalList = ref(0)
    const currentPage = ref(1)
    const perPageOptions = [10, 25, 50, 100]
    const searchQuery = ref('')
    const sortBy = ref('id')
    const isSortDirDesc = ref(true)

    const dataMeta = computed(() => {
        const localItemsCount = refListTable.value ? refListTable.value.localItems.length : 0
        return {
            from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
            to: perPage.value * (currentPage.value - 1) + localItemsCount,
            of: totalList.value,
        }
    })

    const fetchList = (ctx, callback) => {
        store.dispatch('incident/getAll', {
            q: searchQuery.value,
            max: perPage.value,
            'page[n]': currentPage.value,
            sortBy: sortBy.value,
            sortDesc: isSortDirDesc.value,
            id_tenant: tenantFilter.value,
            id_incident_list : listFilter.value
        })
            .then(response => {
                callback(response.data)
                totalList.value = response.meta.total
            })
            .catch(() => {
                toast({
                    component: ToastificationContent,
                    props: {
                        title: 'Error obteniendo la información',
                        icon: 'AlertTriangleIcon',
                        variant: 'danger',
                    },
                })
            })
    }

    const formatDateAssigned = value => {
        const formattedDate = moment(value).format('DD/MM/YYYY HH:mm:ss')
        return formattedDate
    }

    const refetchData = () => {
        refListTable.value.refresh()
        console.log({listOptions});
        console.log(listFilter.value);
        if(!listFilter.value){
           listFilter.value = listOptions.value[0].value
           incident_list_name.value = listOptions.value[0].label
        }else{
            const getList = listOptions.value.find(option => option.value === listFilter.value);
            console.log({getList})
            incident_list_name.value = getList.label
        }
    }

    watch([tenantFilter, listFilter, currentPage, perPage, ], () => {
        refetchData()
    })

    watch([ searchQuery ], debounce(() => {
        refetchData()
    }, 500))

    return {
        tableColumns,
        perPage,
        currentPage,
        totalList,
        fetchList,
        dataMeta,
        perPageOptions,
        searchQuery,
        sortBy,
        isSortDirDesc,
        refListTable,
        formatDateAssigned,

        typeAlarmOptions,
        tenantOptions,
        tenantFilter,
        listFilter,
        listOptions,
        id_incident_list,
        incident_list_name,
        refetchData,
    }
}
